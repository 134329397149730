<template>
  <div>
    <div class="condition">
      <el-input
        class="input public"
        v-model="assetCode"
        placeholder="资产编码"
      ></el-input>
      <el-input
        class="input public"
        v-model="deviceCode"
        placeholder="设备编码"
      ></el-input>
      <span class="public" style="display: inline-block"
        >保存时间
        <el-date-picker
          prefix-icon="el-icon-date"
          v-model="startTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择开始日期时间"
        >
        </el-date-picker>
        <span style="padding: 4px">-</span>
        <el-date-picker
          prefix-icon="el-icon-date"
          v-model="endTimes"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择结束日期时间"
        >
        </el-date-picker>
      </span>
      <el-button class="query button_query" @click="getFunctionDet"
        >查询</el-button
      >
      <el-button class="query button_query" @click="exportFunctionDet"
        >导出</el-button
      >
    </div>
    <div class="table">
      <el-table
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="assetCode" label="资产编码"> </el-table-column>
        <el-table-column prop="deviceCode" label="设备编码"> </el-table-column>
        <el-table-column prop="deviceImei" label="IMEI"> </el-table-column>
        <el-table-column prop="refrigerationCode" label="制冷号">
        </el-table-column>
        <el-table-column prop="recordCh" label="测试记录"> </el-table-column>
        <el-table-column prop="createTime" label="检测时间">
          <template slot-scope="scope">{{
            scope.row.createTime | formattingDate
          }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first()">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last()">尾页</span>
    </div>
  </div>
</template>

<script>
import { functionDet, batch_export_test_record } from "@/api/functionDet.js";
export default {
  name: "index",
  data() {
    return {
      assetCode: "",
      deviceCode: "",
      startTime: null,
      endTimes: null,
      tableData: [],
      pageSize: 12,
      currentPage: 1,
      pages: 1,
      recordCount: 1
    };
  },
  created() {
    this.getFunctionDet();
  },
  methods: {
    //查询
    getFunctionDet() {
      let data = {
        assetCode: this.assetCode,
        deviceCode: this.deviceCode,
        startTime: this.startTime,
        endTime: this.endTimes,
        limit: 12,
        curPage: this.pages
      };
      functionDet(data).then(res => {
        this.tableData = res.data.data.list;
        this.recordCount = res.data.data.total;
      });
    },
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
      this.getFunctionDet();
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.getFunctionDet();
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.getFunctionDet();
      console.log(`当前页: ${val}`);
    },
    //导出
    exportFunctionDet() {
      this.$store.commit("SET_LOADING", true);
      let data = {
        assetCode: this.assetCode,
        deviceCode: this.deviceCode,
        startTime: this.startTime,
        endTime: this.endTimes,
        limit: 12,
        curPage: this.pages
      };
      batch_export_test_record(data)
        .then(res => {
          const dt = new Date();
          const y = dt.getFullYear();
          const m = (dt.getMonth() + 1 + "").padStart(2, "0");
          const d = (dt.getDate() + "").padStart(2, "0");
          const hh = (dt.getHours() + "").padStart(2, "0");
          const mm = (dt.getMinutes() + "").padStart(2, "0");
          const ss = (dt.getSeconds() + "").padStart(2, "0");
          let date = y + m + d + hh + mm + ss;
          const content = res.data; //返回的内容
          let _this = this;
          if (res.data.type !== "application/vnd.ms-excel") {
            //二进制转字符串
            let reader = new FileReader();
            reader.addEventListener("loadend", function(e) {
              // 输出字符串 转化为json对象
              let str = JSON.parse(e.target.result);
              _this.$message({
                showClose: true,
                message: str.desc,
                type: "error"
              });
              if (str.resultCode == 2000) {
                _this.$router.push("/login");
              }
            });
            reader.readAsText(content);
          } else {
            const fileName = "信息列表" + date.toString() + ".xls"; //下载文件名
            //处理下载流
            const blob = new Blob([content]); //创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
            const url = window.URL.createObjectURL(blob); //URL.createObjectURL(object)表示生成一个File对象或Blob对象
            let dom = document.createElement("a"); //设置一个隐藏的a标签，href为输出流，设置download
            dom.style.display = "none";
            dom.href = url;
            dom.setAttribute("download", fileName); //指示浏览器下载url,而不是导航到它；因此将提示用户将其保存为本地文件
            document.body.appendChild(dom);
            dom.click();
          }
        })
        .finally(() => {
          this.$store.commit("SET_LOADING", false);
        });
    }
  }
};
</script>

<style scoped>
.button_query {
  margin-left: 10px;
}
.input {
  width: 246px;
  margin-right: 10px;
}
.public {
  margin-bottom: 20px;
}
</style>
