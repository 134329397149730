import axios from "../utils/request";

//功能检测查询
export function functionDet(data) {
  return axios({
    url: "/clodchainAM/web/testRecord/page",
    method: "get",
    params: data
  });
}
//功能检测查询
export function batch_export_test_record(data) {
  return axios({
    url: "/clodchainAM/web/batch_export_test_record",
    method: "get",
    params: data,
    responseType: "blob"
  });
}
